var exports = {};
exports = exports = typeof Object.keys === "function" ? Object.keys : shim;
exports.shim = shim;

function shim(obj) {
  var keys = [];

  for (var key in obj) keys.push(key);

  return keys;
}

export default exports;